<template>
	<div>
		<el-dialog :draggable="true" width="60%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="120px">
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.orderby" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="材料名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.title" placeholder="请输入材料名称"></el-input>
					</el-form-item>
					<el-form-item label="材料类型">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.type" placeholder="请输入材料类型"></el-input>
					</el-form-item>
					<el-form-item label="材料必要性">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.necessity" placeholder="请输入材料必要性"></el-input>
					</el-form-item>
					<el-form-item label="纸质材料份数">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.number" placeholder="请输入纸质材料份数"></el-input>
					</el-form-item>
					<el-form-item label="办理地址">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.handle_address" placeholder="请输入办理地址"></el-input>
					</el-form-item>
					<el-form-item label="联系方式">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.handle_tel" placeholder="请输入联系方式"></el-input>
					</el-form-item>
					<el-form-item label="填报须知">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.instructions_filling"
							placeholder="请输入填报须知"></el-input>
					</el-form-item>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remarks" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList.length>0" v-for="(item,index) in imgList" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="原表上传">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label=" ">
					<div class="dialog-list">
						<div class="upload-li" v-if="appenList.length>0" v-for="(item,index) in appenList" :key="index">
							<div class="names">
								<p style="width: 100px;color: #333;">原表名称:</p>
								<el-input v-model="item.OriginalName" placeholder="请输入原表名称"></el-input>
							</div>
							<div class="names">
								<p style="width: 100px;color: #333;">原表链接:</p>
								<el-input disabled v-model="item.url" placeholder="请输入原表链接"></el-input>
							</div>
							<p @click="delUpload(index)">删除</p>
						</div>
					</div>
					</el-form-item>
					<!-- 样本上传 -->
					<el-form-item label="样本上传">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="yangSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label=" ">
					<div class="dialog-list">
						<div class="upload-li" v-if="blankTable.length>0" v-for="(item,index) in blankTable" :key="index">
							<div class="names">
								<p style="width: 100px;color: #333;">样本名称:</p>
								<el-input v-model="item.OriginalName" placeholder="请输入样本名称"></el-input>
							</div>
							<div class="names">
								<p style="width: 100px;color: #333;">样本链接:</p>
								<el-input disabled v-model="item.url" placeholder="请输入样本链接"></el-input>
							</div>
							<p @click="delyang(index)">删除</p>
						</div>
					</div>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/approve";
	export default {
		data() {
			return {
				Headers: {
					token: ''
				},
				ActionUrl: '',
				outerVisible: false,
				title: '新增',
				list: [],
				ruleForm: {
					material_cate_id: '',
					title: '',
					necessity: '',
					number: '',
					instructions_filling: '',
					remarks: '',
					imgpath: '',
					files: '',
					orderby: 0,
					type: '',
					material_title_id: '',
					handle_address: '',
					handle_tel: '',
					blank_table:''
				},
				imgList: [],
				appenList: [],
				blankTable:[]
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign = sessionStorage.getItem('sign')

			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		methods: {
			// 样本
			yangSuccess(res, file) {
				this.blankTable.push(res.data)
			},
			//  样本删除
			delyang(index) {
				this.blankTable.splice(index, 1)
			},
			// 删除附件
			delUpload(index) {
				this.appenList.splice(index, 1)
			},
			// 附件
			UploadSuccess(res, file) {
				this.appenList.push(res.data)
			},
			// 删除图片
			deleImg(index) {
				this.imgList.splice(index, 1)
			},
			// 图片
			imgSuccess(res, file) {
				this.imgList.push(res.data.url)
			},
			// 获取分类
			getList() {
				this.$http.post(api.TypeList, {
						page: 1,
						limit: 9999
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.data

						}
					})
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							delete this.ruleForm.create_time
							this.imgList = res.data.imgpath ? res.data.imgpath.split(",") : []
							this.appenList = res.data.files ? JSON.parse(res.data.files) : [],
							this.blankTable = res.data.blank_table ? JSON.parse(res.data.blank_table) : []
						}
					})
			},
			// 显示隐藏
			show(val, id, mid) {
				this.getList()
				this.title = val
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				if (mid) {
					this.ruleForm.material_title_id = mid
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.imgList = []
				this.appenList = []
				
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				this.ruleForm.imgpath = this.imgList.length > 0 ? this.imgList.join(",") : ''
				this.ruleForm.files = this.appenList.length > 0 ? JSON.stringify(this.appenList) : ''
				this.ruleForm.blank_table = this.blankTable.length > 0 ? JSON.stringify(this.blankTable) : ''
				if (this.ruleForm.title === '') {
					ElMessage({
						message: '请输入材料名称',
						type: 'warning',
					})
				} else if (this.ruleForm.type === '') {
					ElMessage({
						message: '请输入材料类型',
						type: 'warning',
					})
				} else if (this.ruleForm.necessity === '') {
					ElMessage({
						message: '请输入材料必要性',
						type: 'warning',
					})
				} else if (this.ruleForm.number === '') {
					ElMessage({
						message: '请输入纸质材料份数',
						type: 'warning',
					})
				} else if (this.ruleForm.handle_address === '') {
					ElMessage({
						message: '请输入办理地址',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>
<style scoped lang="scss">
	.names {
		display: flex;
		align-items: center;
	}

	.Draggable {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.dialog-up {
		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-up-left {
		float: left;
		margin-left: 20px;
	}

	.upload-li {
		font-size: 12px;
		display: flex;
	}

	.upload-li p {
		cursor: pointer;
		color: #999999;
		margin-left: 20px;
	}

	.upload-li p:hover {
		color: #00ad79;
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-img-box {
		cursor: move;
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 10px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 10px;
	}

	.dialog-img-box p {
		font-size: 12px;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>
