import request from './request'

const api = {
	// 获取列表
	lists: '/material/lists',
	// 添加
	add: '/material/add',
	// 删除
	dele: '/material/del',
	// 编辑
	edit: '/material/update',
	// 详情
	info: '/material/read',
	// 获取分类
	TypeList: '/materialcate/lists',
}

export {
	request,
	api
}
